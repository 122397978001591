import * as React from "react";
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import LandingPage from "../../pages/Landing/Landing.page";
import PrivacyPolicyPage from "../../pages/PrivacyPolicy/PrivacyPolicy.page";

const router = createBrowserRouter([
    {
        path: "/",
        element: <LandingPage />,
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicyPage />,
    },
]);

const Router = () => {
    return <RouterProvider router={router} />
}

export default Router;