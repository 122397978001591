import React from 'react';
import './App.scss';
import {MantineProvider} from '@mantine/core';
import Router from "./components/Router/Router";

function App() {
    return (
        <MantineProvider withGlobalStyles withNormalizeCSS theme={{
            colorScheme: 'light', colors: {}
        }}>
            <Router />
        </MantineProvider>
    );
}

export default App;
