import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Mousewheel, Parallax} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Image, Title} from "@mantine/core";
import {use100vh} from "react-div-100vh";

import styles from './Landing.module.scss';

const LandingPage = () => {
    const fullHeight = use100vh();
    return (
        <>
            <Title className={styles.title} size={72} color={'white'}>marsm</Title>
            <div className={styles.mainSlider}>
                <Swiper
                    modules={[Mousewheel, Parallax]}
                    spaceBetween={0}
                    slidesPerView={'auto'}
                    autoHeight
                    mousewheel={{sensitivity: 300}}
                    direction={'vertical'}
                    parallax
                >
                    <SwiperSlide>
                        <div style={{height: fullHeight ?? '100%', width: '100vw'}}>
                            <Swiper
                                modules={[Parallax, Autoplay]}
                                spaceBetween={0}
                                slidesPerView={1}
                                autoHeight
                                mousewheel={{sensitivity: 300}}
                                autoplay={{delay: 6000, disableOnInteraction: false}}
                                loop
                            >
                                <SwiperSlide>
                                    <Image height={'100%'} src={'/assets/images/cine/marsm-1.jpg'}/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Image height={'100%'} src={'/assets/images/cine/marsm-2.jpg'}/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Image height={'100%'} src={'/assets/images/cine/marsm-3.jpg'}/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Image height={'100%'} src={'/assets/images/cine/marsm-4.jpg'}/>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}

export default LandingPage;