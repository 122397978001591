import {Affix, Button, Container, Transition} from "@mantine/core";
import {useWindowScroll} from "@mantine/hooks";
import {useNavigate} from "react-router-dom";

const PrivacyPolicyPage = () => {
    const [scroll] = useWindowScroll();
    const navigate = useNavigate();

    return (
        <Container sx={{backgroundColor: 'white'}}>
            <h1 style={{color: "red", fontWeight: "bold"}}>Entwurf. Nicht rechtskräftig.</h1>
            <h1>Datenschutzerklärung</h1>
            <h2 id="m716">Präambel</h2>
            <p>Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer
                personenbezogenen
                Daten
                (nachfolgend auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang verarbeiten.
                Die
                Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl
                im
                Rahmen
                der Erbringung unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen Applikationen
                sowie
                innerhalb externer Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend zusammenfassend
                bezeichnet
                als "Onlineangebot“).</p>
            <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
            <p>Stand: 24. Mai 2023</p>
            <h2>Inhaltsübersicht</h2>
            <ul className="index">
                <li><a className="index-link" href="#m716">Präambel</a></li>
                <li><a className="index-link" href="#m3">Verantwortlicher</a></li>
                <li><a className="index-link" href="#mOverview">Übersicht der Verarbeitungen</a></li>
                <li><a className="index-link" href="#m13">Maßgebliche Rechtsgrundlagen</a></li>
                <li><a className="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
                <li><a className="index-link" href="#m24">Datenverarbeitung in Drittländern</a></li>
                <li><a className="index-link" href="#m12">Löschung von Daten</a></li>
                <li><a className="index-link" href="#m134">Einsatz von Cookies</a></li>
                <li><a className="index-link" href="#m225">Bereitstellung des Onlineangebotes und Webhosting</a></li>
                <li><a className="index-link" href="#m17">Newsletter und elektronische Benachrichtigungen</a></li>
                <li><a className="index-link" href="#m638">Werbliche Kommunikation via E-Mail, Post, Fax oder
                    Telefon</a></li>
                <li><a className="index-link" href="#m408">Umfragen und Befragungen</a></li>
                <li><a className="index-link" href="#m136">Präsenzen in sozialen Netzwerken (Social Media)</a></li>
                <li><a className="index-link" href="#m328">Plugins und eingebettete Funktionen sowie Inhalte</a></li>
                <li><a className="index-link" href="#m15">Änderung und Aktualisierung der Datenschutzerklärung</a></li>
                <li><a className="index-link" href="#m10">Rechte der betroffenen Personen</a></li>
            </ul>
            <h2 id="m3">Verantwortlicher</h2>

            <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
            <p>Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung
                zusammen und
                verweist auf die betroffenen Personen.</p>
            <h3>Arten der verarbeiteten Daten</h3>
            <ul>
                <li>Bestandsdaten.</li>
                <li>Kontaktdaten.</li>
                <li>Inhaltsdaten.</li>
                <li>Nutzungsdaten.</li>
                <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
            </ul>
            <h3>Kategorien betroffener Personen</h3>
            <ul>
                <li>Kommunikationspartner.</li>
                <li>Nutzer.</li>
                <li>Teilnehmer.</li>
            </ul>
            <h3>Zwecke der Verarbeitung</h3>
            <ul>
                <li>Kontaktanfragen und Kommunikation.</li>
                <li>Sicherheitsmaßnahmen.</li>
                <li>Direktmarketing.</li>
                <li>Feedback.</li>
                <li>Marketing.</li>
                <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
                <li>Informationstechnische Infrastruktur.</li>
            </ul>
            <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
            <p>Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir
                personenbezogene
                Daten
                verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale
                Datenschutzvorgaben in
                Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall speziellere
                Rechtsgrundlagen
                maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.</p>
            <ul>
                <li><strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> - Die betroffene Person hat ihre
                    Einwilligung
                    in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen spezifischen Zweck oder
                    mehrere
                    bestimmte Zwecke gegeben.
                </li>
                <li><strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong> - Die Verarbeitung ist
                    zur
                    Wahrung
                    der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
                    Interessen
                    oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten
                    erfordern,
                    überwiegen.
                </li>
            </ul>
            <p>Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale Regelungen zum Datenschutz in der
                Schweiz.
                Hierzu
                gehört insbesondere das Bundesgesetz zum Datenschutz (DSG). Das DSG gilt insbesondere dann, wenn keine
                EU/EWG-Bürger
                betroffen sind und z.B. nur Daten von Schweizer Bürgern verarbeitet werden.</p>
            <h2 id="m27">Sicherheitsmaßnahmen</h2>
            <p>Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der
                Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
                unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und Freiheiten
                natürlicher
                Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes
                Schutzniveau zu
                gewährleisten.</p>
            <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von
                Daten
                durch
                Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs,
                der
                Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
                Verfahren
                eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die
                Gefährdung
                der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
                Entwicklung
                bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch
                Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.</p>
            <p>Kürzung der IP-Adresse: Sofern IP-Adressen von uns oder von den eingesetzten Dienstleistern und
                Technologien
                verarbeitet werden und die Verarbeitung einer vollständigen IP-Adresse nicht erforderlich ist, wird die
                IP-Adresse
                gekürzt (auch als "IP-Masking" bezeichnet). Hierbei werden die letzten beiden Ziffern, bzw. der letzte
                Teil der
                IP-Adresse nach einem Punkt entfernt, bzw. durch Platzhalter ersetzt. Mit der Kürzung der IP-Adresse
                soll die
                Identifizierung einer Person anhand ihrer IP-Adresse verhindert oder wesentlich erschwert werden.</p>
            <p>TLS-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu schützen, nutzen
                wir eine
                TLS-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der
                Adresszeile
                Ihres
                Browsers.</p>
            <h2 id="m24">Datenverarbeitung in Drittländern</h2>
            <p>Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union (EU), des Europäischen
                Wirtschaftsraums
                (EWR)) verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder der
                Offenlegung
                bzw. Übermittlung von Daten an andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur
                im
                Einklang
                mit den gesetzlichen Vorgaben. </p>
            <p>Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher Übermittlung
                verarbeiten
                oder lassen wir die Daten nur in Drittländern mit einem anerkannten Datenschutzniveau, vertraglichen
                Verpflichtung
                durch sogenannte Standardschutzklauseln der EU-Kommission, beim Vorliegen von Zertifizierungen oder
                verbindlicher
                internen Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO, Informationsseite der
                EU-Kommission: <a
                    href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
                    target="_blank"
                    rel={'noreferrer'}>https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de</a>).
            </p>
            <h2 id="m12">Löschung von Daten</h2>
            <p>Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur
                Verarbeitung
                erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck der
                Verarbeitung
                dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind). Sofern die Daten nicht
                gelöscht
                werden,
                weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung auf diese
                Zwecke
                beschränkt. D.h., die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für
                Daten,
                die
                aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur
                Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
                oder
                juristischen Person erforderlich ist. </p>
            <p>Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung und Löschung von Daten
                beinhalten,
                die
                für die jeweiligen Verarbeitungen vorrangig gelten.</p>
            <h2 id="m134">Einsatz von Cookies</h2>
            <p>Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die Informationen auf Endgeräten
                speichern und
                Informationen aus den Endgeräten auslesen. Z.B. um den Login-Status in einem Nutzerkonto, einen
                Warenkorbinhalt
                in
                einem E-Shop, die aufgerufenen Inhalte oder verwendete Funktionen eines Onlineangebotes speichern.
                Cookies
                können
                ferner zu unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der Funktionsfähigkeit,
                Sicherheit und
                Komfort von Onlineangeboten sowie der Erstellung von Analysen der Besucherströme. </p>
            <p><strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang mit den gesetzlichen
                Vorschriften ein.
                Daher holen wir von den Nutzern eine vorhergehende Einwilligung ein, außer wenn diese gesetzlich nicht
                gefordert
                ist. Eine Einwilligung ist insbesondere nicht notwendig, wenn das Speichern und das Auslesen der
                Informationen,
                also
                auch von Cookies, unbedingt erforderlich sind, um dem den Nutzern einen von ihnen ausdrücklich
                gewünschten
                Telemediendienst (also unser Onlineangebot) zur Verfügung zu stellen. Zu den unbedingt erforderlichen
                Cookies
                gehören in der Regel Cookies mit Funktionen, die der Anzeige und Lauffähigkeit des Onlineangebotes , dem
                Lastausgleich, der Sicherheit, der Speicherung der Präferenzen und Auswahlmöglichkeiten der Nutzer oder
                ähnlichen
                mit der Bereitstellung der Haupt- und Nebenfunktionen des von den Nutzern angeforderten Onlineangebotes
                zusammenhängenden Zwecken dienen. Die widerrufliche Einwilligung wird gegenüber den Nutzern deutlich
                kommuniziert
                und enthält die Informationen zu der jeweiligen Cookie-Nutzung.</p>
            <p><strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf welcher datenschutzrechtlichen
                Rechtsgrundlage wir die personenbezogenen Daten der Nutzer mit Hilfe von Cookies verarbeiten, hängt
                davon ab, ob
                wir
                Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen, ist die Rechtsgrundlage der
                Verarbeitung Ihrer
                Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf
                Grundlage
                unserer berechtigten Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes
                und
                Verbesserung seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung unserer
                vertraglichen
                Pflichten
                erfolgt, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu
                erfüllen. Zu
                welchen Zwecken die Cookies von uns verarbeitet werden, darüber klären wir im Laufe dieser
                Datenschutzerklärung
                oder
                im Rahmen von unseren Einwilligungs- und Verarbeitungsprozessen auf.</p>
            <p><strong>Speicherdauer: </strong>Im Hinblick auf die Speicherdauer werden die folgenden Arten von Cookies
                unterschieden:</p>
            <ul>
                <li><strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong> Temporäre Cookies werden
                    spätestens
                    gelöscht, nachdem ein Nutzer ein Online-Angebot verlassen und sein Endgerät (z.B. Browser oder
                    mobile
                    Applikation) geschlossen hat.
                </li>
                <li><strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem Schließen des
                    Endgerätes
                    gespeichert. So können beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte direkt
                    angezeigt
                    werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die mit Hilfe von Cookies
                    erhobenen Daten
                    der
                    Nutzer zur Reichweitenmessung verwendet werden. Sofern wir Nutzern keine expliziten Angaben zur Art
                    und
                    Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung der Einwilligung), sollten Nutzer
                    davon
                    ausgehen, dass Cookies permanent sind und die Speicherdauer bis zu zwei Jahre betragen kann.
                </li>
            </ul>
            <p><strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong>Nutzer können die von ihnen
                abgegebenen
                Einwilligungen jederzeit widerrufen und zudem einen Widerspruch gegen die Verarbeitung entsprechend den
                gesetzlichen
                Vorgaben im Art. 21 DSGVO einlegen. Nutzer können ihren Widerspruch auch über die Einstellungen ihres
                Browsers
                erklären, z.B. durch Deaktivierung der Verwendung von Cookies (wobei dadurch auch die Funktionalität
                unserer
                Online-Dienste eingeschränkt sein kann). Ein Widerspruch gegen die Verwendung von Cookies zu
                Online-Marketing-Zwecken kann auch über die Websites <a href="https://optout.aboutads.info"
                                                                        target="_blank"
                                                                        rel={'noreferrer'}>https://optout.aboutads.info</a> und <a
                    href="https://www.youronlinechoices.com/"
                    target="_blank" rel={'noreferrer'}>https://www.youronlinechoices.com/</a> erklärt
                werden.
            </p>
            <ul className="m-elements">
                <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
            </ul>
            <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
            <p>Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur Verfügung stellen zu können. Zu
                diesem
                Zweck
                verarbeiten wir die IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer
                Online-Dienste
                an den Browser oder das Endgerät der Nutzer zu übermitteln.</p>
            <ul className="m-elements">
                <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                    Inhalten,
                    Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
                    Identifikationsnummern, Einwilligungsstatus); Inhaltsdaten (z.B. Eingaben in Onlineformularen).
                </li>
                <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
                    Nutzerfreundlichkeit;
                    Informationstechnische Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und
                    technischen
                    Geräten (Computer, Server etc.).); Sicherheitsmaßnahmen.
                </li>
                <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
            </ul>
            <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
            <ul className="m-elements">
                <li><strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff auf unser Onlineangebot wird
                    in Form
                    von
                    so genannten "Server-Logfiles" protokolliert. Zu den Serverlogfiles können die Adresse und Name der
                    abgerufenen
                    Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
                    erfolgreichen
                    Abruf,
                    Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite)
                    und im
                    Regelfall IP-Adressen und der anfragende Provider gehören.

                    Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine
                    Überlastung
                    der
                    Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken)
                    und zum
                    anderen, um die Auslastung der Server und ihre Stabilität
                    sicherzustellen; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
                    f)
                    DSGVO); <strong>Löschung von Daten:</strong> Logfile-Informationen werden für die Dauer von maximal
                    30 Tagen
                    gespeichert und danach gelöscht oder anonymisiert. Daten, deren weitere Aufbewahrung zu
                    Beweiszwecken
                    erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung
                    ausgenommen.
                </li>
                <li><strong>E-Mail-Versand und -Hosting: </strong>Die von uns in Anspruch genommenen
                    Webhosting-Leistungen
                    umfassen
                    ebenfalls den Versand, den Empfang sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die
                    Adressen
                    der
                    Empfänger sowie Absender als auch weitere Informationen betreffend den E-Mailversand (z.B. die
                    beteiligten
                    Provider) sowie die Inhalte der jeweiligen E-Mails verarbeitet. Die vorgenannten Daten können ferner
                    zu
                    Zwecken
                    der Erkennung von SPAM verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im Internet
                    grundsätzlich
                    nicht verschlüsselt versendet werden. Im Regelfall werden E-Mails zwar auf dem Transportweg
                    verschlüsselt,
                    aber
                    (sofern kein sogenanntes Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf den
                    Servern, von
                    denen sie abgesendet und empfangen werden. Wir können daher für den Übertragungsweg der E-Mails
                    zwischen dem
                    Absender und dem Empfang auf unserem Server keine Verantwortung
                    übernehmen; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
                    DSGVO).
                </li>
            </ul>
            <h2 id="m17">Newsletter und elektronische Benachrichtigungen</h2>
            <p>Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen (nachfolgend "Newsletter“)
                nur mit
                der
                Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum
                Newsletter
                dessen
                Inhalte konkret umschrieben werden, sind sie für die Einwilligung der Nutzer maßgeblich. Im Übrigen
                enthalten
                unsere
                Newsletter Informationen zu unseren Leistungen und uns.</p>
            <p>Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich aus, wenn Sie Ihre E-Mail-Adresse
                angeben. Wir
                können Sie jedoch bitten, einen Namen, zwecks persönlicher Ansprache im Newsletter, oder weitere
                Angaben, sofern
                diese für die Zwecke des Newsletters erforderlich sind, zu tätigen.</p>
            <p><strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem Newsletter erfolgt grundsätzlich in
                einem
                sogenannte Double-Opt-In-Verfahren. D.h., Sie erhalten nach der Anmeldung eine E-Mail, in der Sie um die
                Bestätigung
                Ihrer Anmeldung gebeten werden. Diese Bestätigung ist notwendig, damit sich niemand mit fremden
                E-Mail-Adressen
                anmelden kann. Die Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess entsprechend
                den
                rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des Anmelde- und des
                Bestätigungszeitpunkts als auch der IP-Adresse. Ebenso werden die Änderungen Ihrer bei dem
                Versanddienstleister
                gespeicherten Daten protokolliert.</p>
            <p><strong>Löschung und Einschränkung der Verarbeitung: </strong> Wir können die ausgetragenen
                E-Mail-Adressen bis
                zu
                drei Jahren auf Grundlage unserer berechtigten Interessen speichern, bevor wir sie löschen, um eine
                ehemals
                gegebene
                Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten wird auf den Zweck einer möglichen
                Abwehr von
                Ansprüchen beschränkt. Ein individueller Löschungsantrag ist jederzeit möglich, sofern zugleich das
                ehemalige
                Bestehen einer Einwilligung bestätigt wird. Im Fall von Pflichten zur dauerhaften Beachtung von
                Widersprüchen
                behalten wir uns die Speicherung der E-Mail-Adresse alleine zu diesem Zweck in einer Sperrliste
                (sogenannte
                "Blocklist") vor.</p>
            <p>Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen zu
                Zwecken des
                Nachweises seines ordnungsgemäßen Ablaufs. Soweit wir einen Dienstleister mit dem Versand von E-Mails
                beauftragen,
                erfolgt dies auf Grundlage unserer berechtigten Interessen an einem effizienten und sicheren
                Versandsystem.</p>
            <strong>Inhalte:</strong>
            <p>Informationen zu uns, unseren Leistungen, Aktionen und Angeboten.</p>
            <ul className="m-elements">
                <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B.
                    E-Mail,
                    Telefonnummern); Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
                    Identifikationsnummern, Einwilligungsstatus); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                    Inhalten,
                    Zugriffszeiten).
                </li>
                <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
                <li><strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail oder postalisch).</li>
                <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).</li>
                <li><strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Sie können den Empfang unseres Newsletters
                    jederzeit
                    kündigen, d.h. Ihre Einwilligungen widerrufen, bzw. dem weiteren Empfang widersprechen. Einen Link
                    zur
                    Kündigung
                    des Newsletters finden Sie entweder am Ende eines jeden Newsletters oder können sonst eine der oben
                    angegebenen
                    Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu nutzen.
                </li>
            </ul>
            <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
            <ul className="m-elements">
                <li><strong>Messung von Öffnungs- und Klickraten: </strong>Die Newsletter enthalten einen sogenannte
                    "web-beacon“,
                    d.h., eine pixelgroße Datei, die beim Öffnen des Newsletters von unserem Server, bzw., sofern wir
                    einen
                    Versanddienstleister einsetzen, von dessen Server abgerufen wird. Im Rahmen dieses Abrufs werden
                    zunächst
                    technische Informationen, wie Informationen zum Browser und Ihrem System, als auch Ihre IP-Adresse
                    und der
                    Zeitpunkt des Abrufs, erhoben.

                    Diese Informationen werden zur technischen Verbesserung unseres Newsletters anhand der technischen
                    Daten
                    oder
                    der Zielgruppen und ihres Leseverhaltens auf Basis ihrer Abruforte (die mit Hilfe der IP-Adresse
                    bestimmbar
                    sind) oder der Zugriffszeiten genutzt. Diese Analyse beinhaltet ebenfalls die Feststellung, ob die
                    Newsletter
                    geöffnet werden, wann sie geöffnet werden und welche Links geklickt werden. Diese Informationen
                    werden den
                    einzelnen Newsletterempfängern zugeordnet und in deren Profilen bis zu deren Löschung gespeichert.
                    Die
                    Auswertungen dienen uns dazu, die Lesegewohnheiten unserer Nutzer zu erkennen und unsere Inhalte an
                    sie
                    anzupassen oder unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer zu versenden.
                    <br/>
                    <strong>Rechtsgrundlagen:</strong> Einwilligung
                    (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
                </li>
            </ul>
            <h2 id="m638">Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</h2>
            <p>Wir verarbeiten personenbezogene Daten zu Zwecken der werblichen Kommunikation, die über diverse Kanäle,
                wie z.B.
                E-Mail, Telefon, Post oder Fax, entsprechend den gesetzlichen Vorgaben erfolgen kann.</p>
            <p>Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen oder der werblichen
                Kommunikation
                jederzeit zu widersprechen.</p>
            <p>Nach Widerruf oder Widerspruch speichern wir die zum Nachweis der bisherigen Berechtigung erforderlichen
                Daten
                zur
                Kontaktaufnahme oder Zusendung bis zu drei Jahre nach Ablauf des Jahres des Widerrufs oder Widerspruchs
                auf der
                Grundlage unserer berechtigten Interessen. Die Verarbeitung dieser Daten ist auf den Zweck einer
                möglichen
                Abwehr
                von Ansprüchen beschränkt. Auf der Grundlage des berechtigten Interesses, den Widerruf bzw. Widerspruch
                der
                Nutzer
                dauerhaft zu beachten, speichern wir ferner die zur Vermeidung einer erneuten Kontaktaufnahme
                erforderlichen
                Daten
                (z.B. je nach Kommunikationskanal die E-Mail-Adresse, Telefonnummer, Name).</p>
            <ul className="m-elements">
                <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B.
                    E-Mail,
                    Telefonnummern).
                </li>
                <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
                <li><strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail oder postalisch).</li>
                <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); Berechtigte
                    Interessen
                    (Art.
                    6 Abs. 1 S. 1 lit. f) DSGVO).
                </li>
            </ul>
            <h2 id="m408">Umfragen und Befragungen</h2>
            <p>Wir führen Umfragen und Befragungen durch, um Informationen für den jeweils kommunizierten Umfrage- bzw.
                Befragungszweck, zu sammeln. Die von uns durchgeführten Umfragen und Befragungen (nachfolgend
                "Befragungen")
                werden
                anonym ausgewertet. Eine Verarbeitung personenbezogener Daten erfolgt nur insoweit, als dies zu
                Bereitstellung
                und
                technischen Durchführung der Umfragen erforderlich ist (z.B. Verarbeitung der IP-Adresse, um die Umfrage
                im
                Browser
                des Nutzers darzustellen oder mithilfe eines Cookies eine Wiederaufnahme der Umfrage zu
                ermöglichen).</p>
            <ul className="m-elements">
                <li><strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten
                    (z.B.
                    Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                    Zugriffszeiten);
                    Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern,
                    Einwilligungsstatus).
                </li>
                <li><strong>Betroffene Personen:</strong> Kommunikationspartner; Teilnehmer.</li>
                <li><strong>Zwecke der Verarbeitung:</strong> Feedback (z.B. Sammeln von Feedback via Online-Formular).
                </li>
                <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
            </ul>
            <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
            <p>Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten in diesem Rahmen Daten der
                Nutzer,
                um
                mit den dort aktiven Nutzern zu kommunizieren oder um Informationen über uns anzubieten.</p>
            <p>Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union
                verarbeitet werden
                können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte
                der
                Nutzer
                erschwert werden könnte.</p>
            <p>Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für Marktforschungs- und
                Werbezwecke
                verarbeitet. So können z.B. anhand des Nutzungsverhaltens und sich daraus ergebender Interessen der
                Nutzer
                Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B.
                Werbeanzeigen
                innerhalb und außerhalb der Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen.
                Zu
                diesen
                Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das
                Nutzungsverhalten und
                die
                Interessen der Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig
                der von
                den
                Nutzern verwendeten Geräte gespeichert werden (insbesondere, wenn die Nutzer Mitglieder der jeweiligen
                Plattformen
                sind und bei diesen eingeloggt sind).</p>
            <p>Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der Widerspruchsmöglichkeiten
                (Opt-Out)
                verweisen wir auf die Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.</p>
            <p>Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen wir darauf hin,
                dass
                diese am
                effektivsten bei den Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils Zugriff auf
                die
                Daten
                der Nutzer und können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch
                Hilfe
                benötigen, dann können Sie sich an uns wenden.</p>
            <ul className="m-elements">
                <li><strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten
                    (z.B.
                    Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                    Zugriffszeiten);
                    Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern,
                    Einwilligungsstatus).
                </li>
                <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation; Feedback (z.B. Sammeln
                    von
                    Feedback
                    via Online-Formular); Marketing.
                </li>
                <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
            </ul>
            <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
            <ul className="m-elements">
                <li><strong>Instagram: </strong>Soziales Netzwerk; <strong>Dienstanbieter:</strong> Meta Platforms
                    Irland
                    Limited, 4
                    Grand Canal Square, Grand Canal Harbour, Dublin 2,
                    Irland; <strong>Rechtsgrundlagen:</strong> Berechtigte
                    Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong> <a
                        href="https://www.instagram.com"
                        target="_blank"
                        rel={'noreferrer'}>https://www.instagram.com</a>; <strong>Datenschutzerklärung:</strong>
                    <a href="https://instagram.com/about/legal/privacy"
                       target="_blank" rel={'noreferrer'}>https://instagram.com/about/legal/privacy</a>.
                </li>
            </ul>
            <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
            <p>Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von den Servern ihrer
                jeweiligen
                Anbieter
                (nachfolgend bezeichnet als "Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel um
                Grafiken, Videos
                oder Stadtpläne handeln (nachfolgend einheitlich bezeichnet als "Inhalte”).</p>
            <p>Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse der Nutzer
                verarbeiten,
                da
                sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für
                die
                Darstellung dieser Inhalte oder Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu
                verwenden, deren
                jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können
                ferner
                sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder
                Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den
                Seiten
                dieser Webseite, ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem
                Gerät der
                Nutzer
                gespeichert werden und unter anderem technische Informationen zum Browser und zum Betriebssystem, zu
                verweisenden
                Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten als auch
                mit
                solchen
                Informationen aus anderen Quellen verbunden werden.</p>
            <ul className="m-elements">
                <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                    Inhalten,
                    Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
                    Identifikationsnummern, Einwilligungsstatus).
                </li>
                <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
                    Nutzerfreundlichkeit.
                </li>
                <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
            </ul>
            <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
            <ul className="m-elements">
                <li><strong>Einbindung von Drittsoftware, Skripten oder Frameworks (z. B. jQuery): </strong>Wir binden
                    in unser
                    Onlineangebot Software ein, die wir von Servern anderer Anbieter abrufen (z.B.
                    Funktions-Bibliotheken, die
                    wir
                    zwecks Darstellung oder Nutzerfreundlichkeit unseres Onlineangebotes verwenden). Hierbei erheben die
                    jeweiligen
                    Anbieter die IP-Adresse der Nutzer und können diese zu Zwecken der Übermittlung der Software an den
                    Browser
                    der
                    Nutzer sowie zu Zwecken der Sicherheit, als auch zur Auswertung und Optimierung ihres Angebotes
                    verarbeiten.
                    -
                    Wir binden in unser Onlineangebot Software ein, die wir von Servern anderer Anbieter abrufen (z.B.
                    Funktions-Bibliotheken, die wir zwecks Darstellung oder Nutzerfreundlichkeit unseres Onlineangebotes
                    verwenden).
                    Hierbei erheben die jeweiligen Anbieter die IP-Adresse der Nutzer und können diese zu Zwecken der
                    Übermittlung
                    der Software an den Browser der Nutzer sowie zu Zwecken der Sicherheit, als auch zur Auswertung und
                    Optimierung
                    ihres Angebotes verarbeiten; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs.
                    1 S. 1
                    lit.
                    f) DSGVO).
                </li>
            </ul>
            <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
            <p>Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen
                die
                Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies
                erforderlich
                machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z.B.
                Einwilligung)
                oder eine sonstige individuelle Benachrichtigung erforderlich wird.</p>
            <p>Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und
                Organisationen
                angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern können und bitten die
                Angaben vor
                Kontaktaufnahme zu prüfen.</p>
            <h2 id="m10">Rechte der betroffenen Personen</h2>
            <p>Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis
                21 DSGVO
                ergeben:</p>
            <ul>
                <li><strong>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
                    ergeben,
                    jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art.
                    6 Abs.
                    1
                    lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen
                    gestütztes
                    Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu
                    betreiben,
                    haben
                    Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen
                    Daten zum
                    Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher
                    Direktwerbung
                    in
                    Verbindung steht.</strong></li>
                <li><strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte Einwilligungen
                    jederzeit
                    zu
                    widerrufen.
                </li>
                <li><strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
                    betreffende
                    Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie
                    der
                    Daten
                    entsprechend den gesetzlichen Vorgaben.
                </li>
                <li><strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben das Recht,
                    die
                    Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen
                    Daten zu
                    verlangen.
                </li>
                <li><strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach Maßgabe der
                    gesetzlichen
                    Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw.
                    alternativ
                    nach
                    Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.
                </li>
                <li><strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten, die Sie
                    uns
                    bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und
                    maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen anderen Verantwortlichen zu
                    fordern.
                </li>
                <li><strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet eines anderweitigen
                    verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
                    Aufsichtsbehörde,
                    insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des
                    Orts des
                    mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
                    personenbezogenen
                    Daten gegen die Vorgaben der DSGVO verstößt.
                </li>
            </ul>
            <p className="seal"><a href="https://datenschutz-generator.de/"
                                   title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                                   target="_blank" rel="noopener noreferrer nofollow">Erstellt mit kostenlosem
                Datenschutz-Generator.de
                von Dr.
                Thomas Schwenke</a></p>
            <Affix position={{bottom: '2rem', right: '2rem'}}>
                <Transition transition="slide-up" mounted={scroll.y > 0}>
                    {(transitionStyles) => (
                        <Button
                            style={transitionStyles}
                            onClick={() => navigate('/')}
                        >
                            Zurück zur Startseite
                        </Button>
                    )}
                </Transition>
            </Affix>
        </Container>
    )
}

export default PrivacyPolicyPage;